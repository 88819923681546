.headerContainer{
    background-color: #faf8f8;
    display: flex;
    width: 100%;
    padding: 0.5rem;
    justify-content: space-around;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    user-select: none;
}
.navToggle, .navClose{
    display: none;
}
.navList{
    display: flex;
    list-style-type: none;
    gap: 1.5rem;
}
.nav__link{
    text-decoration: none;
    font-size: 20px;
    color: black;
    width: 100%;
    text-align: center;
}
.nav__link div{
    width: 70px;
    height: 1.7px;
    margin-top: 4px;
    background-color: transparent;
    transition: 0.3s all ease;
}
.nav__link:hover div{
    background-color: #dbc4a3;
    margin-top: 4px;
}
.navItem:nth-child(2) a{
    transition: .3s ease;
}
.navItem:nth-child(2):hover a{
    color: var(--primary);
}
.active-link div{
    margin-top: 4px;
    background-color: #B47A1A;
    transition: 0.3s all ease;
    animation-name: navactive;
    animation-duration: 0.3s;
    animation-iteration-count: initial;
}
@keyframes navactive {
    from {
        margin-left: -20rem; }
}

/* NAV dropdown */
.dropMobile{
    display: none;
}
.navItem{
    padding: 0.3rem;
}

.dropdownFalse{
    display: none;
}
.dropdown{
    cursor: pointer;
    position: absolute;
    top: 0;
    margin-top: 4.1rem;
    margin-left: -8px;
    animation-name: dropdownAnimation;
    animation-duration: 0.3s;
    animation-iteration-count: initial;
    background-color: #fefefe;
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1rem;
    font-size: 15px;
    box-shadow: 1px 2px 3px rgb(75, 74, 74);
    border: 0.5px solid transparent;
    transition: .3s ease;
}
.dropdown:hover{
    border: 0.5px solid #B47A1A;
}

@keyframes dropdownAnimation {
    from {
        margin-top: 3.2rem;
    }
}


@media screen and (max-width: 788px){
        /* Código para icono hamburguesa */
        .dropMobile{
            cursor: pointer;
            margin: auto;
            margin-top: 8px;
            margin-bottom: 0;
            width: 60%;
            padding: 8px;
            border: 0.3px solid rgba(126, 126, 126, 0.536);
            border-radius: 8px;
            background-color: #ffffff;
            display: block;
            text-align: center;
            font-size: 12px;
            transition: .3s ease;
        }
        .dropMobile:active{
            background-color: #ffffff;
            border: 0.3px solid black;
        }
        .dropdown{
            display: none;
        }
        .burger-menu {
            height: 100%;
            width: 2rem;
            margin-top: 8px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;
            gap: 0.4rem;
            cursor: pointer;
        }
        .burger-bar {
            width: 2rem;
            height: 0.1em;
            background-color: #000000D9;
            border-radius: 0.5em;
        }
        
        .burger-bar.clicked:nth-child(1){
            transform: rotate(45deg) translate(0.15em, .25em);
            transition: ease-out 0.4s;
        }
        
        .burger-bar.clicked:nth-child(2){
            transform: scale(0.01);
            transition: ease-out 0.4s;
        }
        
        .burger-bar.clicked:nth-child(3){
            transform: rotate(135deg) translate(-0.3em, 0.4em);
            transition: ease-out 0.4s;
        }
        
        /* unclicked */
        .burger-bar.unclicked {
            transform: rotate(0) translate(0);
            transition: cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.5s;
        }
    /* Termina css hamburguesa*/

    .logo svg{
        width: 35px;
        margin-left: 0.3rem;
    }
    .headerContainer{
        justify-content: space-between;
        padding: 0.6rem;
    }
    .navMenu{
        z-index: 1;
        padding: 0.5rem;
        margin: 1rem 0;
        border-radius: 8px;
        position: fixed;
        background-color: #f7f7f7;
        box-shadow: 1px 2px 8px rgb(75, 74, 74);
        right: -200px;
        transition: .3s
    }
    .showMenu{
        right: 0;
    }
    .navList{
        display: flex;
        flex-direction: column;
        gap: 0.5px;
        text-align: end;
    }
    .nav__link{
        font-size: 15px;
        transition: 0.3s;
        padding: 0.5rem;
    }
    .navItem{
        text-align: center;
        padding: 0.5rem;
        border-radius: 8px;
    }
    .navItem:hover{
        background-color: #ffffff;

    }
    .navToggle, .navClose {
        display: block;
        font-size: 1.5rem;
        cursor: pointer;
    }
    .navToggle{
        position: absolute;
        top: 0;
        right: 0;
        padding: 2.2rem 1rem;
    }
    .navClose{
        position: absolute;
        top: -1;
        right: 0;
    }
    .nav__link div{
        display: none;
    }


}

@media screen and (max-width: 550px){
    .headerContainer{
        height: 80px;
        padding: 0.5rem;
    }
    .logo svg{
        width: 28px;
        margin-left: 0.7rem;
        margin-top: 6px;
    }
    .burger-menu {
        height: 100%;
        width: 2rem;
    }
    .burger-bar {
        width: 1.8rem;
        height: 0.1em;
    }
    .navToggle{
        padding: 1.5rem;
    }
    .navMenu{
        margin-top: 2rem;
    }
}
.overlay{
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(10px);
    height: 100%;
    width: 100%;
    position: fixed;
    margin: auto;
}

.popupContainer{
    margin: auto;
    margin-top: 2rem;
    padding: 1rem;
    background-color: #f7f7f7;
    border-radius: 1rem;
    width: 75%;
    height: 600px;
    overflow-y: scroll;
    overflow-x: hidden;
    z-index: 101;
}
.overlay i{
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-self: center;
    margin: 2rem 4rem;
    border-radius: 50%;
    box-shadow: 0px 0px 5px 4px #0000003d;
    color: black;
    background-color: var(--primary);
    justify-content: center;
    height: 40px;
    width: 40px;
    font-size: 42px;
    cursor: pointer;
    transition: .3s all ease;
    color: white;
}
.overlay i:hover{
    transform: rotate(-90deg);
}


.popupList{
    display: flex;
    flex-direction: row;
    justify-content: start;
    gap: 1rem;
    flex-wrap: wrap;

}
.popupItem{
    margin: 0.5rem;
    width: 40%;
}
.popupItem h2{
    padding: 0.5rem;
}

.popupItem ul{
    display: flex;
    flex-direction: column;
    margin-left: 8px;
    gap: 0.5rem;
}


@media screen and (max-width: 788px) {
        /* Popup */
    .popupContainer{
        margin: auto;
        margin-top: 1rem;
        padding: 0.5rem;
        background-color: #f7f7f7;
        border-radius: 1rem;
        width: 85%;
        height: 500px;
        overflow-y: scroll;
        overflow-x: hidden;
        z-index: 3;
    }
    .overlay i{
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        align-self: center;
        margin: 1rem 0.5rem;
        border-radius: 50%;
        box-shadow: 0px 0px 5px 4px #0000006a;
        color: black;
        background-color: var(--primary);
        width: 30px;
        height: 30px;
        font-size: 32px;
        cursor: pointer;
        transition: .3s all ease;
        color: white;
    }
    .popupItem h2{
        font-size: var(--title-mobile);
    }
    .popupItem ul li{
        font-size: var(--text-mobile);
    }
    .popupItem{
        margin: 0.5rem;
        width: 80%;
    }
}