.contactoTitle{
    text-align: center;
    font-size: var(--title-mobile);
    font-weight: 400;
}
.contactoConteiner{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.map{
    width: 90%;
    height: 400px;
    margin: 10px;
}
.contactoInfo{
    display: flex;
    justify-content: center;
}
.contactoInfoDetail{
    width: 70%;
    margin: 5px;
    text-align: center;
}
.contactoInfoDetail h3{
    font-size: var(--title-mobile);
    margin-bottom: 5px;
    font-weight: 400;
}
.contactoInfo p{
    font-size: var(--text-mobile);
    font-weight: 400;
}
.redesConteiner{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px;
}
.redesConteiner svg{
    color: var(--primary);
    border: solid 1px;
    width: 36px;
    padding: 10px;
    margin: 0px 10px;
}
.redesConteiner svg:hover{
    background-color: var(--primary);
    border: 1px solid var(--third);
    transition: 0.3s;
}

.redesConteiner svg:hover path{
    fill: var(--third);
    transition: 0.3s;
}



@media screen and (min-width:788px){
    .contactoTitle{
        font-size: var(--title);
        margin-bottom: 35px;
    }
    .contactoConteiner{
        flex-direction: row;
    }
    .map{
        width: 50%;
        zoom: 1.2;
    }
    .contactoInfo{
        flex-direction: column;
        align-items: center;
    }

    .contactoInfoDetail{
        width: 70%;
        margin: 15px;
        text-align: start;

    }
    .contactoInfoDetail h3{
        font-size: 35px;
        align-items: center;
        justify-content: center;
    }
    .contactoInfo p{
        font-size: 25px;
        align-items: center;
        justify-content: center;
    }
    .redesConteiner{
        justify-content: space-between;
        width: 70%;
        margin-left: 60px;
    }
    .redesConteiner svg{
        width: 70px;
        padding: 20px;
    }
}