.quienes1Container{
    position: relative;
    width: 100%;
    height: 70vh;
    background-image: url(../../assets/quienes_somos_img.jpeg);
    background-size:cover;
    background-repeat: no-repeat;
    background-position: center;
    
}
.home{
    overflow-x: hidden;
}
.quienesDiv{
    width: 100%;
    height: 70vh;
    background-color: #00000066;
}
.quienesTitle{
    position: absolute;
    z-index: 1;
    width: 45%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #00000033;
    color: white;
}

.quienesTitle h1{
    font-weight: lighter;
    font-size: var(--title);
    width: 200px;
}
.buttonQuienes{
    display: none;
}

.separador{
    width: 100%;
    height: 17px;
    background-color: #B47A1A;
}

@media screen and (min-width: 990px){
    .quienesTitle h1{
        font-weight: lighter;
        width: 300px;
        font-size: 45px;
    }
}

@media screen and (max-width: 989px){
    .quienes1Container{
        height: 60vh;
    }
    .quienesDiv{
        height: 60vh;
    }

    .quienesTitle h1{
        width: 200px;
        font-size: 30px;
    }
}
@media screen and (max-width: 550px){
    .quienes1Container, .quienesDiv{
        height: 35vh;
    }
    .quienesTitle{
        flex-direction: column;
        align-items: center;
        gap: 1.6rem;
    }
    .quienesTitle h1{
        align-self: center;
        font-size: 20px;
        font-weight: 400;
        width: 140px;
        line-height: 25px;
    }
    .buttonQuienes{
        display: block;
        text-decoration: none;
        color: white;
        padding: 5px 13px;
        border: 0.389344px solid #FFFFFF;
    }
    .separador{
        display: none;
    }
}

/****** QUIENES SOMOS ********/

.quienesContainer{
    margin: 1rem 0;
    display: grid;
    grid-template-columns: 0.45fr 2px 0.5fr;
    align-items: center;

    padding: 5rem 2rem;
    width: 100%;
}

.separador2{
    height: 100%;
    width: 1px;
    background-color: #B47A1A;
    margin: auto;
}
.quienesTitle2{
    justify-self: center;
    padding-left: 1.8rem;
}
.quienesTexto{
    padding: 1rem 0 0 3rem;
}
.quienesTexto p{
    font-size: var(--text);
    line-height: 35px;
}
.quienesTitle2 h2{
    color: #B47A1A;
    font-size: 30px;
    font-weight: lighter;
}
.quienesTitle2 h3{
    font-size: 45px;
    font-weight: lighter;
}
@media screen and (max-width: 788px){
    .quienesContainer{
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: start;
        padding: 1.5rem 1rem;
        width: 100%;
    }
    .separador2{
        display: none;
    }
    .sep{
        width: 120%;
        height: 1.5px;
        background-color: #B47A1A;
        margin: 0.5rem 0 1rem 0;
    }
    .quienesTitle2{
        padding-left: 0rem;
        justify-self: start;
    }
    .quienesTexto{
        padding: 0;
        margin: 1.5rem 0 0 0;
    }
    .quienesTexto p{
        font-size: 12px;
        line-height: 17px;
    }
    .quienesTitle2 h2{
        font-size: var(--title-mobile);
    }
    .quienesTitle2 h3{
        font-size: var(--title-mobile);
    }
}