.dragContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 1.5rem;
    padding: 2rem;
}
.dragContainer h3{
    font-size: 40px;
    font-weight: 400;
    text-align: center;
}
.textMobileDrag{
    display: none;
}
.dragContainer p{
    color: var(--primary);
    font-size: 25px;
    text-align: center;
}
.drag__area{
    width: 60%;
    margin-top: 2rem;
    padding: 10rem 0 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: end;
    background-color: #B47A1A1A;
    transition: .3s ease;
    border: 2px solid transparent;
}

.drag__area__active{
    width: 60%;
    margin-top: 2rem;
    padding: 10rem 0 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: end;
    background-color: #b4791a49;
    transition: .3s ease;
    border: 2px solid var(--primary);
}
#dragIcon{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: end;
    gap: 2rem;
}
#dragIcon svg{
    opacity: 0.7;
}

.button{
    cursor: pointer;
    margin-top: 1rem;
    padding: 1rem 5rem;
    background-color: var(--primary);
    color: white;
    font-size: 20px;
    border: 1px solid transparent;
    transition: .15s ease;
    box-shadow: 0px 7.000000476837158px 7.000000476837158px 0px #00000040;

}
.button:hover{
    background-color: #B47A1A1A;
    color: var(--secondary);
    border: 1px solid var(--secondary);
    
}
.button:active{
    transform: scale(0.9);
    box-shadow: none;
}
.iconClick{
    cursor: pointer;
    padding: 1rem;
    border: 0.5px solid transparent;
    transition: 0.3s ease;
}
.iconClick:hover{
    opacity: 0.7;
}
.listFiles li{
    margin-top: 10px;
    list-style: none;
    color: var(--secondary);
}

@media screen and (max-width: 788px){
    .dragContainer{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: start;
        padding: 1rem;
    }
    .dragContainer h3{
        font-size: var(--title-mobile);
        font-weight: 400;
    }
    .dragContainer p{
        font-size: var(--text-mobile);
    }
    .textMobileDrag{
        display: block;
    }
    .textDesktopDrag{
        display: none;
    }
    .drag__area{
        width: 90%;
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: var(--background);
        transition: .3s ease;
        border: 2px solid transparent;
    }
    .drag__area__active{
        width: 90%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: var(--background);
        transition: .3s ease;
        border: 2px solid transparent;
    }
    #dragIcon{
        display: flex;
    }
    #dragIcon svg{
        opacity: 1;
    }
    .iconClick{
        background-color: #F6F4F0;
    }
    .button{
        cursor: pointer;
        margin-top: 1rem;
        padding: 1rem 5rem;
        width: 80%;
        background-color: var(--primary);
        color: white;
        font-size: 15px;
        border: 1px solid transparent;
        transition: .15s ease;
        box-shadow: 0px 7.000000476837158px 7.000000476837158px 0px #00000040;
    
    }
}