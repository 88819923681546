*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Old Standard TT', serif;
  font-style: normal;
  scroll-behavior: smooth;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
main{
  margin-top: 100px;
  margin-bottom: 50px;
  width: 100%;
  height: 100%;
  
}
body{
  height: 100%;
}
.wspButton{
  z-index: 201;
  box-shadow: 4px 4px 4px 4px #00000040;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin: 0rem 2rem 3rem;
  position: fixed;
  right: 0;
  bottom: 0;
  background-color: var(--primary);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: .3s ease;
}

.wspButton svg{
  width: 35px;
}
.wspButton:hover{
  opacity: 0.8;
}
@media screen and (max-width: 550px){
  main{
    margin-top: 80px;
    margin-bottom: 100px;
  }
  .wspButton{
    width: 45px;
    height: 45px;
    border-radius: 50%;
    margin: 1.8rem 0.41rem 3.5rem;
  }
  .wspButton svg{
    width: 25px;
    height: 25px;
    margin: auto;
  }
}

:root{
  --background: #FFFFFF;
  --primary: #B47A1A;
  --secondary: #692020;
  --third: #3D0C0C;

  
  --text: 20px;
  --text-mobile: 12px;
  --title: 45px;
  --title-mobile: 14px;
}

