ul{
    list-style-type: none;
}

.misionVisionConteiner{
    display: flex;
    flex-direction: row;
    position: relative;
    overflow-x: hidden;
}

.visionConteiner{
    width: 50vw;
    left: 0px;
    background: #692020;
    padding: 8px;    

}

/* Visión */
.visionConteiner h2{
    font-size: 14px;
    line-height: 17px;
    display: flex;
    justify-content: center;

    color: #FFFFFF;

}

.visionConteiner p{
    font-weight: 400;
    font-size: var(--text-mobile);
    line-height: 15px;
    margin-top: 12px;    
    color: #FFFFFF;

}

.misionConteiner{
    width: 50vw;
    left: 180px;
    background: #3D0C0C;
    padding: 8px;

}

.misionConteiner h2{
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    justify-content: center;
        
    color: #FFFFFF;
}

.misionConteiner p{
    margin-top: 12px;
    font-weight: 400;
    font-size: var(--text-mobile);
    line-height: 15px;
    display: flex;
    align-items: center;

    color: #FFFFFF;

}

.logo2{
    z-index: 1;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)
}

.valoresConteiner{
    text-align: center;
    margin: 36px 0;
}
.valoresConteiner h2{
    font-weight: 400;
    font-size: var(--title-mobile);
    line-height: 17px;
    color: #000000
}
.valoresConteiner h3{
    font-weight: 400;
    font-size: var(--title-mobile);
    line-height: 17px;
    color: #B47A1A
}
.valoresItems{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin: 1rem 30px;
}

.item{
    flex-direction: column;
    margin: 15px;
}
.item li{
    font-weight: 400;
    font-size: 9.74285px;
    line-height: 12px;
}
.item svg{
    height: 57px;
}


@media screen and (min-width:788px){
    .visionConteiner{
        padding: 66px 51px 84px 60px;
    }
    .visionConteiner h2{
        font-size: var(--title);
        line-height: 62px;
        justify-content: start;
    }
    .visionConteiner p{
        margin-top: 52px;    
        font-size: var(--text);
        line-height: 37px;
    }
    .misionConteiner{
        padding: 66px 32px 47px 72px;
    }
    .misionConteiner h2{
        font-size: var(--title);
        line-height: 62px;
        justify-content: start;
    }
    .misionConteiner p{
        margin-top: 52px;
        font-size: var(--text);
        line-height: 37px;
    }
    .valoresConteiner{
        margin: 102px 0px;
    }
    .valoresConteiner h2{
        font-size: var(--title);
        line-height: 62px;
    }
    .valoresConteiner h3{
        font-size: 25px;
        line-height: 37px;
    }
    .valoresItems{
        width: 75%;
        margin: 3rem auto;
        display: flex;
        justify-content: center;
    }
    .item{
        width: 280px;
    }
    .item svg{
        height: 140px;
    }
    .item li{
        font-size: 30px;
        line-height: 37px;
    }
}

@media screen and (min-width: 930px) {
    .visionConteiner{
        padding: 66px 51px 84px 110px;
    }
}