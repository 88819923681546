.serviciosConteiner{
    display: flex;
}
.serviciosTitle{
    text-align: center;
    font-weight: 400;
    font-size: var(--title-mobile);
    line-height: 17px;
    margin: 1.5em;
}

.notarialesConteiner{
    position: relative;
    background-image: url(../../assets/notariales-img.png);
    background-size:cover;
    width: 50vw;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.notarialesItems{
    margin:0px 15px;
}
.juridicosConteiner{
    position: relative;
    background-image: url(../../assets/juridicos-img.png);
    background-size:cover;
    width: 50vw;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 17px;
}
.blackBox{
    position: absolute;
    background-color: rgba(0, 0, 0, 0.7);
    width: 100%;
    height: 100%;
}

.servicioSvg {
    width:7.36px;
    margin-right: 5px;
}
.notarialesConteiner h3, .juridicosConteiner h3{
    font-weight: 700;
    font-size: var(--text-mobile);
    line-height: 12px;
    z-index: 1;
    margin: 17px;
}
.notarialesConteiner h3{
    padding-top: 17px;
}

.serviciosItem,.serviciosText, .serviciosList h4 {
    font-weight: 400;
    font-size: 7.36058px;
    line-height: 9px;
}
.notarialesItems li:before, .juridicosItems li::before {
    content: "-";
    margin-right: 5px;
}

.notarialesItems li:first-letter, .juridicosItems li:first-letter {
    margin-left: -7.5px;
}
.serviciosItemsConteiner{
    z-index: 1;
    margin: 5px;
}
.serviciosList{
    margin-bottom:9px ;
}
@media screen and (min-width:788px){
    .serviciosTitle{
        font-size: 50px;
        line-height: 62px;
    }
    .notarialesConteiner h3, .juridicosConteiner h3{
        font-size: 40px;
        line-height: 49px;
        margin: 73px;
    }
    .notarialesConteiner h3{
        padding-top: 66px;
    }
    .notarialesItems{
        margin:0px 62px;
    }
    .servicioSvg{
        width:30px;
    }
    .serviciosItem,.serviciosText, .serviciosList h4{
        font-size: 30px;
        line-height: 37px;
    }
    .serviciosList{
        margin-bottom:37px ;
    }
    .juridicosConteiner{
        padding: 66px;
    }
    .notarialesItems li:first-letter, .juridicosItems li:first-letter {
        margin-left: -16px;
    }
}